import React, { Component } from 'react';
import axios from 'axios';

import './App.css';

export default class App extends Component {
    state = {
        rows: []
    }

    componentDidMount() {
        axios.get(`http://browser.smowete.com/api/index.php`).then(
            response => {
                const rawData = response.data;
                let rows = [];
                for (let i = 0; i < rawData.length; i += 4) {
                    rows = [...rows, rawData.slice(i, i+4)];
                }
                this.setState({rows});
            },
            error => {
                console.log("Error", error);
            }
        )
    }

    render() {
        return (
            <div id="main">
                <div id="top">
                    <h1>What's for today?</h1>
                    <a href="http://browser.smowete.com/bookmarks.html"><button>bookmarks</button></a>
                </div>
                {
                    this.state.rows.map((row) => {
                        return (
                            <div className="row" key={`row_${row[0].name}`}>
                                {
                                    row.map((item) => {
                                        return (
                                            <a href={item.url} key={`item_${item.name}`} >
                                                <div className="page" style={{background: item.color}}>
                                                    <div className="div-title">
                                                        <h2 className="title">{item.name}</h2>
                                                    </div>
                                                    <div className="div-img">
                                                        <img className="div-img" src={`http://browser.smowete.com/img/div-img/${item.img}`} />
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
